import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { EditUser } from 'auth/pages'

type EditarPerfilProps = PageProps
interface EditarPerfilState {
  mounted: boolean
}
class EditarPerfil extends Component<EditarPerfilProps, EditarPerfilState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <EditUser />
        </AAnimate>
      </Layout>
    )
  }
}

export default EditarPerfil
